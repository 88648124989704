export const suppressResizeObserverErrors = () => {
  // Suppress console errors
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (
      args[0]?.toString().includes?.('ResizeObserver') ||
      args[0]?.message?.includes?.('ResizeObserver') ||
      args[0]?.stack?.includes?.('ResizeObserver') ||
      args[0]?.name?.includes?.('ResizeObserver')
    ) {
      return;
    }
    originalConsoleError.apply(console, args);
  };

  // Suppress window errors
  const originalOnError = window.onerror;
  window.onerror = (msg, url, lineNo, columnNo, error) => {
    if (
      msg?.includes?.('ResizeObserver') ||
      error?.message?.includes?.('ResizeObserver') ||
      error?.stack?.includes?.('ResizeObserver') ||
      error?.name?.includes?.('ResizeObserver')
    ) {
      return true; // Prevents the error from surfacing
    }
    return originalOnError ? originalOnError(msg, url, lineNo, columnNo, error) : false;
  };

  // Suppress unhandled rejections
  const originalOnUnhandledRejection = window.onunhandledrejection;
  window.onunhandledrejection = (event) => {
    if (
      event.reason?.message?.includes?.('ResizeObserver') ||
      event.reason?.stack?.includes?.('ResizeObserver') ||
      event.reason?.name?.includes?.('ResizeObserver')
    ) {
      event.preventDefault();
      return true;
    }
    return originalOnUnhandledRejection ? originalOnUnhandledRejection(event) : false;
  };

  // Add event listeners with capture
  window.addEventListener('error', (event) => {
    if (
      event.message?.includes?.('ResizeObserver') ||
      event.error?.message?.includes?.('ResizeObserver')
    ) {
      event.stopImmediatePropagation();
      event.preventDefault();
      return true;
    }
  }, true);

  window.addEventListener('unhandledrejection', (event) => {
    if (event.reason?.message?.includes?.('ResizeObserver')) {
      event.stopImmediatePropagation();
      event.preventDefault();
      return true;
    }
  }, true);
}; 