import React from 'react';
import { Box, Container, Grid, Typography, Stack, Button, Link, IconButton, Divider } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import BedIcon from '@mui/icons-material/Bed';
import SearchIcon from '@mui/icons-material/Search';
import InventoryIcon from '@mui/icons-material/Inventory';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const quickLinks = [
    { text: 'Home', path: '/' },
    { text: 'Services', path: '/services' },
    { text: 'Pricing', path: '/pricing' },
    { text: 'About', path: '/about' },
    { text: 'Contact', path: '/contact' },
  ];

  const services = [
    { text: 'Professional Cleaning', path: '/services/cleaning', icon: <CleaningServicesIcon sx={{ fontSize: 20 }} /> },
    { text: 'Linen and Laundry', path: '/services/linen', icon: <BedIcon sx={{ fontSize: 20 }} /> },
    { text: 'Property Inspection', path: '/services/inspection', icon: <SearchIcon sx={{ fontSize: 20 }} /> },
    { text: 'Restocking Service', path: '/services/restocking', icon: <InventoryIcon sx={{ fontSize: 20 }} /> },
    { text: 'Guest Support', path: '/services/support', icon: <SupportAgentIcon sx={{ fontSize: 20 }} /> },
  ];

  const legalLinks = [
    { text: 'Terms & Conditions', path: '/terms' },
    { text: 'Privacy Policy', path: '/privacy' },
    { text: 'Cookie Policy', path: '/cookies' },
  ];

  return (
    <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
      <Container maxWidth="lg">
        <Grid container spacing={8}>
          {/* Logo and Company Info */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 800,
                  background: 'linear-gradient(135deg, #4318FF 0%, #39B8FF 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                }}
              >
                Mop Magic
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 3 }}>
              Professional Airbnb Management Services in Dublin.
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" sx={{ mb: 4, fontWeight: 600 }}>
              Quick Links
            </Typography>
            <Stack spacing={2}>
              {quickLinks.map((link) => (
                <Link
                  key={link.text}
                  component="button"
                  onClick={() => handleNavigation(link.path)}
                  sx={{ 
                    color: 'text.secondary',
                    textAlign: 'left',
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    p: 0,
                    '&:hover': {
                      color: 'primary.main',
                      transform: 'translateX(5px)',
                    },
                  }}
                >
                  {link.text}
                </Link>
              ))}
            </Stack>
          </Grid>

          {/* Services */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ mb: 4, fontWeight: 600 }}>
              Services
            </Typography>
            <Stack spacing={2}>
              {services.map((link) => (
                <Link
                  key={link.text}
                  component="button"
                  onClick={() => handleNavigation(link.path)}
                  sx={{ 
                    color: 'text.secondary',
                    textAlign: 'left',
                    textDecoration: 'none',
                    transition: 'all 0.2s',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    p: 0,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    '&:hover': {
                      color: 'primary.main',
                      transform: 'translateX(5px)',
                    },
                  }}
                >
                  {link.icon}
                  {link.text}
                </Link>
              ))}
            </Stack>
          </Grid>

          {/* Legal Links -> Social Media Icons */}
          <Grid item xs={12} sm={6} md={3}>
            <Stack 
              direction="row" 
              spacing={2}
              sx={{
                justifyContent: { xs: 'center', md: 'flex-end' }
              }}
            >
              <IconButton
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  color: 'text.secondary',
                  '&:hover': { 
                    color: '#1877F2',
                    transform: 'translateY(-2px)',
                  },
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <FacebookRoundedIcon />
              </IconButton>
              <IconButton
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  color: 'text.secondary',
                  '&:hover': { 
                    color: '#E4405F',
                    transform: 'translateY(-2px)',
                  },
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                href="https://x.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  color: 'text.secondary',
                  '&:hover': { 
                    color: '#000000',
                    transform: 'translateY(-2px)',
                  },
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <XIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>

        <Divider sx={{ my: 6, borderColor: 'grey.200' }} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'center', sm: 'center' },
          }}
        >
          <Typography variant="body2" sx={{ 
            color: 'text.secondary', 
            mb: { xs: 1, sm: 0 },
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}>
            © {new Date().getFullYear()} Mop Magic. All rights reserved. Made with{' '}
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                fontSize: '1.4rem',
                lineHeight: 0,
                color: '#169B62',
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.1)' },
                  '100%': { transform: 'scale(1)' },
                },
              }}
            >
              ❤
            </Box>
            {' '}in Dublin.
          </Typography>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={{ xs: 1, sm: 2 }} 
            alignItems={{ xs: 'center', sm: 'center' }}
          >
            {legalLinks.map((link, index) => (
              <React.Fragment key={link.text}>
                <Link
                  component="button"
                  onClick={() => handleNavigation(link.path)}
                  sx={{ 
                    color: 'text.secondary',
                    textDecoration: 'none',
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    p: 0,
                    display: 'inline-flex',
                    alignItems: 'center',
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  {link.text}
                </Link>
                {index < legalLinks.length - 1 && (
                  <Box 
                    component="span" 
                    sx={{ 
                      display: { xs: 'none', sm: 'inline' },
                      color: 'text.secondary',
                      fontSize: '0.875rem',
                      lineHeight: 1.43,
                    }}
                  >
                    •
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer; 