import React, { useState, useEffect } from 'react';
import {
  Box,
  Fab,
  Drawer,
  IconButton,
  Typography,
  TextField,
  Paper,
  Stack,
  Avatar,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import { getChatResponse } from '../services/openai';
import { useChat } from '../context/ChatContext';
import { useResizeObserver } from '../hooks/useResizeObserver';

const AIChatHistory = () => {
  const { isOpen, messages, toggleChat, addMessage } = useChat();
  const [input, setInput] = useState('');
  
  const [messagesEndRef, resizeUtils] = useResizeObserver((entries) => {
    scrollToBottom();
  }, { 
    debounceTime: 100,
    onError: (error) => {
      console.warn('Chat resize error:', error);
    }
  });

  // Pause observation when chat is closed
  useEffect(() => {
    if (!isOpen) {
      resizeUtils.pause();
    } else {
      resizeUtils.resume();
    }
  }, [isOpen, resizeUtils]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ 
        behavior: "smooth",
        block: "end"
      });
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (input.trim()) {
      addMessage({ text: input, sender: 'user' });
      const userInput = input;
      setInput('');

      try {
        const response = await getChatResponse(userInput);
        const cleanedResponse = response.replace(/^(Hello|Hi)(!|,)?\s*/i, '');
        addMessage({ text: cleanedResponse, sender: 'bot' });
      } catch (error) {
        console.error('Chat error:', error);
        addMessage({ 
          text: "I'm sorry, I'm having trouble connecting. Please try again.", 
          sender: 'bot' 
        });
      }
    }
  };

  return (
    <>
      {/* Chat Button */}
      <Fab
        color="primary"
        aria-label="chat"
        onClick={toggleChat}
        sx={{
          position: 'fixed',
          bottom: 32,
          right: 32,
          zIndex: 1000,
        }}
      >
        <ChatIcon />
      </Fab>

      {/* Chat Drawer */}
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleChat}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: 400 },
            maxWidth: '100%',
            height: { xs: '80vh', sm: '600px' },
            position: 'fixed',
            bottom: { xs: 0, sm: 100 },
            right: { xs: 0, sm: 32 },
            top: 'auto',
            borderRadius: { xs: '16px 16px 0 0', sm: '16px' },
          },
        }}
        variant="temporary"
        hideBackdrop={true}
        ModalProps={{
          container: document.body,
          keepMounted: true,
          sx: {
            '& .MuiDrawer-root': {
              position: 'absolute',
            },
          },
        }}
      >
        {/* Chat Header */}
        <Box
          sx={{
            p: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Chat with Us</Typography>
          <IconButton onClick={toggleChat}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Chat Messages */}
        <Box
          sx={{
            flexGrow: 1,
            p: 2,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            bgcolor: 'grey.50',
          }}
        >
          {messages.map((message, index) => (
            <Box
              key={index}
              sx={{
                alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
                maxWidth: '80%',
                display: 'flex',
                gap: 1,
                flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
                alignItems: 'flex-start',
              }}
            >
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: message.sender === 'user' ? 'grey.300' : 'primary.main',
                }}
              >
                {message.sender === 'user' ? (
                  <PersonIcon fontSize="small" />
                ) : (
                  <ChatIcon fontSize="small" />
                )}
              </Avatar>

              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  bgcolor: message.sender === 'user' 
                    ? '#4318FF' 
                    : 'white',
                  borderRadius: '12px',
                  boxShadow: message.sender === 'user'
                    ? '0 2px 5px rgba(67, 24, 255, 0.1)'
                    : '0 2px 5px rgba(0, 0, 0, 0.08)',
                }}
              >
                <Typography 
                  variant="body2"
                  component="div"
                  sx={{ 
                    fontWeight: 500,
                    lineHeight: 1.5,
                    color: message.sender === 'user' 
                      ? '#FFFFFF'
                      : 'text.primary',
                  }}
                >
                  {message.text}
                </Typography>
              </Paper>
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </Box>

        {/* Chat Input */}
        <Box
          sx={{
            p: 2,
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              placeholder="Type your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSend();
                }
              }}
              size="small"
            />
            <IconButton
              color="primary"
              onClick={handleSend}
              disabled={!input.trim()}
            >
              <SendIcon />
            </IconButton>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default AIChatHistory; 