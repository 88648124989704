import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import App from './App';
import { suppressResizeObserverErrors } from './utils/suppressResizeObserverErrors';

// Suppress webpack HMR user agent logs
const originalConsoleLog = console.log;
console.log = function() {
  // Skip webpack HMR user agent logs
  if (arguments[0] === navigator.userAgent && 
      (new Error().stack || '').includes('webpack')) {
    return;
  }
  originalConsoleLog.apply(console, arguments);
};

// Llamar a la función inmediatamente
suppressResizeObserverErrors();

// Add global styles for smooth scrolling and better animations
const GlobalStyles = () => (
  <style jsx global>{`
    html {
      scroll-behavior: smooth;
    }

    body {
      overflow-x: hidden;
      background-color: #F4F7FE;
    }

    .page-transition-enter {
      opacity: 0;
      transform: translateY(20px);
    }
    .page-transition-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 400ms, transform 400ms;
    }
    .page-transition-exit {
      opacity: 1;
    }
    .page-transition-exit-active {
      opacity: 0;
      transition: opacity 400ms;
    }

    .MuiCard-root {
      transition: all 0.3s ease;
    }
    .MuiCard-root:hover {
      transform: translateY(-5px);
      box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.18);
    }

    .MuiButton-root {
      transition: all 0.2s ease;
    }
    .MuiButton-root:hover {
      transform: translateY(-1px);
    }

    .MuiAppBar-root {
      background-color: rgba(255, 255, 255, 0.8) !important;
      backdrop-filter: saturate(180%) blur(10px);
    }

    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }

    .MuiDrawer-root {
      z-index: 1300;
    }
  `}</style>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyles />
      <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.4 }}
        >
          <App />
        </motion.div>
      </AnimatePresence>
    </BrowserRouter>
  </React.StrictMode>
);
