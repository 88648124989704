import React, { useEffect } from 'react';
import {
  Box,
  Drawer,
  Typography,
  IconButton,
  Stack,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { motion } from 'framer-motion';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';

const Cart = ({ open, onClose }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { state, removeItem, updateFrequency } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      // Cleanup any subscriptions or state here
    };
  }, []);

  if (!open) return null;

  const handleCheckout = () => {
    onClose();
    navigate('/pricing');
  };

  const drawerWidth = isDesktop ? 400 : '100%';

  const formatPrice = (price) => `€${price.toLocaleString()}`;

  // Helper function for price calculations
  function calculatePriceBreakdown(items) {
    const VAT_RATE = 0.23;

    // Calculate totals
    const breakdown = items.reduce((acc, item) => {
      const price = item.pricing[item.frequency].price;
      
      if (item.frequency === 'yearly') {
        let originalPrice, discountAmount, finalPrice;

        // Set correct prices based on package/service
        switch (item.title) {
          case "Premium":
            originalPrice = 12000;
            discountAmount = originalPrice * 0.25;  // 25% of €12,000 = €3,000
            finalPrice = originalPrice - discountAmount;  // €12,000 - €3,000 = €9,000
            break;
          case "Starter":
            originalPrice = 6000;
            discountAmount = originalPrice * 0.25;  // 25% of €6,000 = €1,500
            finalPrice = originalPrice - discountAmount;  // €6,000 - €1,500 = €4,500
            break;
          case "Deep Cleaning":
            originalPrice = 4800;
            discountAmount = originalPrice * 0.20;  // 20% of €4,800 = €960
            finalPrice = originalPrice - discountAmount;  // €4,800 - €960 = €3,840
            break;
          default:
            // For other services with 25% discount
            originalPrice = price / 0.75;  // Work backwards from final price
            discountAmount = originalPrice * 0.25;
            finalPrice = originalPrice - discountAmount;
        }

        // Calculate VAT from final price
        const netPrice = Math.round(finalPrice / (1 + VAT_RATE) * 100) / 100;
        const vatAmount = Math.round((finalPrice - netPrice) * 100) / 100;

        return {
          originalTotal: acc.originalTotal + originalPrice,
          discountTotal: acc.discountTotal + discountAmount,
          netTotal: acc.netTotal + netPrice,
          vatTotal: acc.vatTotal + vatAmount,
          finalTotal: acc.finalTotal + finalPrice
        };
      } else {
        // For monthly items - no discount
        const netPrice = Math.round(price / (1 + VAT_RATE) * 100) / 100;
        const vatAmount = Math.round((price - netPrice) * 100) / 100;

        return {
          originalTotal: acc.originalTotal + price,
          discountTotal: acc.discountTotal,
          netTotal: acc.netTotal + netPrice,
          vatTotal: acc.vatTotal + vatAmount,
          finalTotal: acc.finalTotal + price
        };
      }
    }, {
      originalTotal: 0,
      discountTotal: 0,
      netTotal: 0,
      vatTotal: 0,
      finalTotal: 0
    });

    // Round all final values
    return {
      originalTotal: Math.round(breakdown.originalTotal * 100) / 100,
      discountTotal: Math.round(breakdown.discountTotal * 100) / 100,
      netTotal: Math.round(breakdown.netTotal * 100) / 100,
      vatTotal: Math.round(breakdown.vatTotal * 100) / 100,
      finalTotal: Math.round(breakdown.finalTotal * 100) / 100
    };
  }

  const priceBreakdown = calculatePriceBreakdown(state.items);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      variant={isDesktop ? "persistent" : "temporary"}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          p: 3,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5">Your Cart</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {state.items.length === 0 ? (
          <Box sx={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center' 
          }}>
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20
              }}
            >
              <ShoppingCartIcon 
                sx={{ 
                  fontSize: 80,
                  color: 'grey.300',
                  mb: 2,
                }}
              />
            </motion.div>
            <Typography color="text.secondary" align="center" sx={{ mb: 2 }}>
              Your cart is empty
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                navigate('/pricing');
              }}
            >
              View Services
            </Button>
          </Box>
        ) : (
          <>
            <Stack spacing={2} sx={{ flex: 1, overflow: 'auto' }}>
              {state.items.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    p: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {item.title}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {item.type === 'package' ? 'Package' : 'Additional Service'}
                      </Typography>
                    </Box>
                    <IconButton
                      size="small"
                      onClick={() => removeItem(item.id)}
                      sx={{ 
                        color: 'text.secondary',
                        padding: 1,
                        width: '32px',
                        height: '32px',
                        minWidth: '32px',
                        minHeight: '32px',
                        borderRadius: '50%',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        },
                      }}
                    >
                      <DeleteOutlineIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Select
                      size="small"
                      value={item.frequency}
                      onChange={(e) => updateFrequency(item.id, e.target.value)}
                      sx={{ minWidth: 120 }}
                      MenuProps={{
                        BackdropProps: { 
                          style: { display: 'none' }  // Remove backdrop
                        },
                        PaperProps: {
                          sx: { 
                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)' 
                          }
                        }
                      }}
                    >
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                    <Typography color="primary.main" fontWeight="bold">
                      {formatPrice(item.frequency === 'yearly' 
                        ? item.pricing.yearly.price
                        : item.pricing.monthly.price
                      )}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Stack>

            {state.discounts.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1, 
                    mb: 1,
                  }}
                >
                  <Chip 
                    icon={<LocalOfferIcon fontSize="small" />}
                    label="Applied Discounts"
                    size="small"
                    sx={{
                      bgcolor: 'turquoise.light',
                      color: 'turquoise.main',
                      fontWeight: 600,
                      fontSize: '0.75rem',
                      height: '24px',
                      borderRadius: '6px',
                      '& .MuiChip-icon': {
                        color: 'turquoise.main',
                        fontSize: '16px',
                        marginLeft: '8px',
                      },
                      '& .MuiChip-label': {
                        padding: '0 8px',
                      },
                    }}
                  />
                </Typography>
                <List dense>
                  {state.discounts.map((discount, index) => (
                    <ListItem key={index} sx={{ px: 0 }}>
                      <ListItemText
                        primary={discount.description}
                        secondary={`-${formatPrice(discount.amount)}`}
                        primaryTypographyProps={{ variant: 'body2' }}
                        secondaryTypographyProps={{ 
                          color: 'turquoise.main',
                          fontWeight: 'bold'
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            <Box sx={{ mt: 'auto', pt: 3 }}>
              <Divider sx={{ mb: 2 }} />
              <Stack spacing={1}>
                {/* Original price */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary">Original Price</Typography>
                  <Typography>{formatPrice(priceBreakdown.originalTotal)}</Typography>
                </Box>

                {/* Discount section */}
                {priceBreakdown.discountTotal > 0 && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography color="turquoise.main" fontWeight="bold">Discount</Typography>
                    <Typography color="turquoise.main" fontWeight="bold">
                      -{formatPrice(priceBreakdown.discountTotal)}
                    </Typography>
                  </Box>
                )}

                {/* Price breakdown */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary">Net Price (excl. VAT)</Typography>
                  <Typography>{formatPrice(priceBreakdown.netTotal)}</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary">VAT (23%)</Typography>
                  <Typography>{formatPrice(priceBreakdown.vatTotal)}</Typography>
                </Box>

                {/* Final total */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    pt: 1,
                    mt: 1,
                    borderTop: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <Typography variant="h6">Total (incl. VAT)</Typography>
                  <Typography variant="h6" color="primary.main">
                    {formatPrice(priceBreakdown.finalTotal)}
                  </Typography>
                </Box>

                {/* VAT notice */}
                <Typography 
                  variant="caption" 
                  color="text.secondary" 
                  align="right"
                  sx={{ mt: 1 }}
                >
                  All prices include VAT at 23%
                </Typography>
              </Stack>

              <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={handleCheckout}
                sx={{ mt: 2 }}
              >
                Proceed to Checkout
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default Cart; 