// Debounce function to limit callback frequency
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Custom ResizeObserver wrapper with error handling and debouncing
export class SafeResizeObserver {
  constructor(callback, options = {}) {
    this.targets = new Set();
    this.frameId = null;
    
    // Debounce the callback
    this.debouncedCallback = debounce(callback, options.debounceTime || 16);
    
    try {
      this.observer = new ResizeObserver((entries) => {
        // Cancel any pending frame
        if (this.frameId) {
          cancelAnimationFrame(this.frameId);
        }
        
        // Schedule the callback in the next frame
        this.frameId = requestAnimationFrame(() => {
          this.debouncedCallback(entries);
          this.frameId = null;
        });
      });
    } catch (error) {
      console.warn('ResizeObserver not supported:', error);
      this.observer = null;
    }
  }

  observe(target) {
    if (!this.observer || !target) return;
    
    try {
      this.observer.observe(target);
      this.targets.add(target);
    } catch (error) {
      console.warn('Failed to observe target:', error);
    }
  }

  unobserve(target) {
    if (!this.observer || !target) return;
    
    try {
      this.observer.unobserve(target);
      this.targets.delete(target);
    } catch (error) {
      console.warn('Failed to unobserve target:', error);
    }
  }

  disconnect() {
    if (!this.observer) return;
    
    try {
      this.observer.disconnect();
      this.targets.clear();
      if (this.frameId) {
        cancelAnimationFrame(this.frameId);
        this.frameId = null;
      }
    } catch (error) {
      console.warn('Failed to disconnect observer:', error);
    }
  }
}

// Global error handler for ResizeObserver
export const setupResizeObserverErrorHandler = () => {
  const originalError = window.onerror;
  
  window.onerror = function(msg, url, lineNo, columnNo, error) {
    if (msg.includes('ResizeObserver') || 
        (error && error.message && error.message.includes('ResizeObserver'))) {
      // Log for debugging but prevent error from surfacing
      console.debug('Suppressed ResizeObserver error:', msg);
      return true; // Prevents the error from surfacing
    }
    return originalError ? originalError(msg, url, lineNo, columnNo, error) : false;
  };
}; 