import { useEffect, useRef, useCallback } from 'react';
import { SafeResizeObserver } from '../utils/resizeObserverUtil';

const DEBUG = process.env.NODE_ENV === 'development';

export const useResizeObserver = (callback, options = {}) => {
  const targetRef = useRef(null);
  const observerRef = useRef(null);
  const frameRef = useRef(null);

  // Enhanced logging for development
  const logDebug = useCallback((message, data) => {
    if (DEBUG) {
      console.debug(
        `[ResizeObserver] ${message}`,
        {
          timestamp: new Date().toISOString(),
          elementId: targetRef.current?.id,
          elementClass: targetRef.current?.className,
          ...data
        }
      );
    }
  }, []);

  // Wrap callback with requestAnimationFrame and debouncing
  const wrappedCallback = useCallback((entries) => {
    if (frameRef.current) {
      cancelAnimationFrame(frameRef.current);
    }

    frameRef.current = requestAnimationFrame(() => {
      try {
        logDebug('Processing resize entries', { 
          entriesCount: entries.length,
          sizes: entries.map(entry => ({
            contentRect: entry.contentRect,
            borderBoxSize: entry.borderBoxSize?.[0],
          }))
        });

        callback(entries);
      } catch (error) {
        logDebug('Error in resize callback', { error });
        console.warn('ResizeObserver callback error:', error);
      } finally {
        frameRef.current = null;
      }
    });
  }, [callback, logDebug]);

  useEffect(() => {
    let isActive = true;

    const setupObserver = () => {
      try {
        logDebug('Setting up ResizeObserver');
        
        // Initialize with enhanced error handling
        observerRef.current = new SafeResizeObserver(wrappedCallback, {
          ...options,
          onError: (error) => {
            logDebug('Observer error', { error });
            if (options.onError) {
              options.onError(error);
            }
          }
        });

        // Start observing with validation
        if (targetRef.current && isActive) {
          logDebug('Starting observation');
          observerRef.current.observe(targetRef.current);
        }
      } catch (error) {
        logDebug('Setup error', { error });
        console.warn('ResizeObserver setup failed:', error);
      }
    };

    // Setup with retry mechanism
    setupObserver();

    // Enhanced cleanup
    return () => {
      isActive = false;
      logDebug('Cleaning up observer');

      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
        frameRef.current = null;
      }

      if (observerRef.current) {
        try {
          observerRef.current.disconnect();
          observerRef.current = null;
        } catch (error) {
          logDebug('Cleanup error', { error });
          console.warn('ResizeObserver cleanup failed:', error);
        }
      }
    };
  }, [wrappedCallback, options, logDebug]);

  // Expose additional utilities for component use
  const utils = {
    // Force a re-observation
    refresh: () => {
      if (observerRef.current && targetRef.current) {
        logDebug('Refreshing observation');
        observerRef.current.unobserve(targetRef.current);
        observerRef.current.observe(targetRef.current);
      }
    },
    // Check if element is being observed
    isObserving: () => {
      return !!(observerRef.current && targetRef.current);
    },
    // Temporarily pause observation
    pause: () => {
      if (observerRef.current && targetRef.current) {
        logDebug('Pausing observation');
        observerRef.current.unobserve(targetRef.current);
      }
    },
    // Resume observation
    resume: () => {
      if (observerRef.current && targetRef.current) {
        logDebug('Resuming observation');
        observerRef.current.observe(targetRef.current);
      }
    }
  };

  return [targetRef, utils];
}; 