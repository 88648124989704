import { createTheme } from '@mui/material/styles';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4318FF',
      light: '#6C49FF',
      dark: '#2B3674',
      contrastText: '#fff',
    },
    secondary: {
      main: '#39B8FF',
      light: '#4FD1C5',
      dark: '#2B3674',
      contrastText: '#fff',
    },
    background: {
      default: '#F4F7FE',
      paper: '#ffffff',
    },
    text: {
      primary: '#2B3674',
      secondary: '#707EAE',
    },
    divider: 'rgba(224, 225, 235, 0.9)',
    navy: {
      main: '#2B3674',
      light: '#363C6C',
      dark: '#1B2559',
    },
    success: {
      main: '#4FD1C5',
      light: '#68D4CA',
      dark: '#3AA99F',
      contrastText: '#fff',
    },
    turquoise: {
      main: '#0E7C86',
      light: '#E6F6F8',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 1.2,
      color: '#2B3674',
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 700,
      lineHeight: 1.3,
      color: '#2B3674',
    },
    h3: {
      fontSize: '1.875rem',
      fontWeight: 700,
      lineHeight: 1.4,
      color: '#2B3674',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 1.4,
      color: '#2B3674',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.5,
      color: '#2B3674',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.5,
      color: '#2B3674',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
      color: '#707EAE',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.6,
      color: '#707EAE',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          fontSize: '1rem',
          padding: '10px 24px',
          transition: 'all 0.2s ease-in-out',
          fontWeight: 500,
          '&.no-hover': {
            transform: 'none !important',
            '&:hover': {
              transform: 'none !important',
            },
          },
        },
        sizeLarge: {
          fontSize: '1.1rem',
          padding: '12px 32px',
        },
        contained: {
          '&:not(.no-elevation)': {
            boxShadow: '0 4px 7px -1px rgba(67, 24, 255, 0.15), 0 2px 4px -1px rgba(67, 24, 255, 0.15)',
            '&:hover': {
              boxShadow: '0 6px 11px -2px rgba(67, 24, 255, 0.25), 0 3px 6px -2px rgba(67, 24, 255, 0.2)',
              transform: 'translateY(-1px)',
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
          transition: 'all 0.3s ease',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'saturate(180%) blur(10px)',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
          borderBottom: '1px solid rgba(224, 225, 235, 0.9)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          fontFamily: 'Montserrat, sans-serif',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat, sans-serif',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiListItemButton-root': {
            '&:hover': {
              backgroundColor: 'rgba(67, 24, 255, 0.08)',  // Light primary color
            },
            '&.Mui-selected': {
              backgroundColor: 'rgba(67, 24, 255, 0.12)',  // Slightly darker primary color
              color: '#4318FF',  // Primary color
              '&:hover': {
                backgroundColor: 'rgba(67, 24, 255, 0.16)',  // Even darker on hover when selected
              },
            },
          },
          '& .MuiListItemText-primary': {
            fontWeight: 500,
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 16,
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
});

export default theme; 