import React, { createContext, useContext, useReducer } from 'react';

const CartContext = createContext();

const initialState = {
  items: [],
  total: 0,
  discounts: [],
};

function cartReducer(state, action) {
  let newState;
  
  switch (action.type) {
    case 'ADD_ITEM':
      const baseId = action.payload.id.split('-')[0];
      const hasItemWithSameBaseId = state.items.some(item => 
        item.id.split('-')[0] === baseId && 
        item.frequency === action.payload.frequency
      );
      
      if (hasItemWithSameBaseId) {
        return state;
      }
      
      newState = {
        ...state,
        items: [...state.items, action.payload],
      };
      break;

    case 'REMOVE_ITEM':
      newState = {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
      };
      break;

    case 'UPDATE_FREQUENCY':
      const oldId = action.payload.id;
      const newFrequency = action.payload.frequency;
      const item = state.items.find(item => item.id === oldId);
      
      if (!item) return state;
      
      const baseItemId = oldId.split('-')[0];
      const newId = `${baseItemId}-${newFrequency}`;
      
      newState = {
        ...state,
        items: state.items.map(item =>
          item.id === oldId
            ? { ...item, id: newId, frequency: newFrequency }
            : item
        ),
      };
      break;

    default:
      return state;
  }

  // Calculate discounts and total
  const { discounts, totalDiscount, finalTotal } = calculateDiscounts(newState.items);
  newState.discounts = discounts;
  newState.totalDiscount = totalDiscount;
  newState.total = finalTotal;

  return newState;
}

function calculateDiscounts(items) {
  let discounts = [];
  let totalDiscount = 0;

  // Group items by discount rate
  const yearlyItems25 = items.filter(item => 
    item.frequency === 'yearly' && item.title !== "Deep Cleaning"
  );
  const yearlyItems20 = items.filter(item => 
    item.frequency === 'yearly' && item.title === "Deep Cleaning"
  );

  // Calculate discounts for 25% group
  if (yearlyItems25.length > 0) {
    const discount25 = yearlyItems25.reduce((sum, item) => {
      // Use originalPrice from yearly pricing
      const originalPrice = item.pricing.yearly.originalPrice;
      const finalPrice = item.pricing.yearly.price;
      return sum + (originalPrice - finalPrice);
    }, 0);

    discounts.push({
      type: 'Annual Plan',
      description: '25% off billed annually',
      amount: discount25
    });
    totalDiscount += discount25;
  }

  // Calculate discounts for 20% group (Deep Cleaning)
  if (yearlyItems20.length > 0) {
    const discount20 = yearlyItems20.reduce((sum, item) => {
      // Use originalPrice from yearly pricing
      const originalPrice = item.pricing.yearly.originalPrice;
      const finalPrice = item.pricing.yearly.price;
      return sum + (originalPrice - finalPrice);
    }, 0);

    discounts.push({
      type: 'Annual Plan',
      description: '20% off billed annually',
      amount: discount20
    });
    totalDiscount += discount20;
  }

  return {
    discounts,
    totalDiscount,
    finalTotal: items.reduce((sum, item) => sum + item.pricing[item.frequency].price, 0)
  };
}

export function CartProvider({ children }) {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  const addItem = (item, onFirstItemAdded) => {
    const isFirstItem = state.items.length === 0;
    dispatch({ type: 'ADD_ITEM', payload: item });
    
    // Call the callback if this is the first item
    if (isFirstItem && onFirstItemAdded) {
      onFirstItemAdded();
    }
  };

  const removeItem = (itemId) => {
    dispatch({ type: 'REMOVE_ITEM', payload: itemId });
  };

  const updateFrequency = (itemId, frequency) => {
    dispatch({ type: 'UPDATE_FREQUENCY', payload: { id: itemId, frequency } });
  };

  return (
    <CartContext.Provider value={{ 
      state, 
      addItem, 
      removeItem, 
      updateFrequency 
    }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
} 