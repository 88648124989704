import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { 
      text: "Hello! I'm here to help you with our Airbnb management services. How can I assist you today?", 
      sender: 'bot' 
    }
  ]);

  const toggleChat = () => setIsOpen(!isOpen);
  const closeChat = () => setIsOpen(false);
  const openChat = () => setIsOpen(true);

  const addMessage = (message) => {
    setMessages(prev => [...prev, message]);
  };

  return (
    <ChatContext.Provider 
      value={{ 
        isOpen, 
        messages, 
        toggleChat, 
        closeChat, 
        openChat, 
        addMessage 
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}; 