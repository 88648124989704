const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';

const fallbackResponses = {
  default:
    "I'm here to assist you with information about Mop Magic's cleaning and Airbnb management services. How can I help you today?",

  keywords: {
    // General Services
    services:
      "At Mop Magic, we offer comprehensive Airbnb management services, including professional cleaning, linen service, property inspection, restocking essentials, and 24/7 guest support. Which service would you like to know more about?",

    // Cleaning Services
    cleaning:
      "Our professional cleaning services ensure your property is immaculate for every guest. We offer both standard and deep cleaning options. Would you like details on what's included?",
    "deep cleaning":
      "Our deep cleaning service includes everything from standard cleaning, plus detailed attention to appliances, windows, and hard-to-reach areas. It's perfect for periodic maintenance.",
    sanitize:
      "We use eco-friendly, hospital-grade sanitization products to ensure your property is not only clean but also safe for your guests.",

    // Linen Service
    linen:
      "Our linen and laundry service provides fresh, professionally laundered linens and towels for every guest. Would you like to know about our pricing?",
    laundry:
      "We handle all laundry needs, ensuring all linens and towels are cleaned to the highest standards between guest stays.",
    bedding:
      "We provide high-quality bedding services, including laundering and regular replacement of linens to maintain guest comfort.",

    // Property Management
    management:
      "Our full property management services cover guest communication, maintenance coordination, and more, allowing you to be a hands-off host. Interested in a complete solution?",
    maintenance:
      "We coordinate all maintenance needs, conducting regular inspections and arranging repairs with trusted local professionals.",
    inspection:
      "Our property inspections after each guest stay ensure your property remains in top condition. We provide detailed reports and address any issues promptly.",

    // Pricing and Packages
    pricing:
      "Our packages are tailored to your property's needs. The Starter Package starts at €600/month, and the Premium Package at €1,000/month. Would you like more details?",
    prices:
      "We offer competitive pricing for all our services. For example, standard cleaning is €240/month. Would you like a full list of our services and prices?",
    packages:
      "We have two main packages: Starter (€600/month) and Premium (€1,000/month), along with individual services you can add. Which one interests you?",
    cost:
      "Our services are priced competitively. We can provide a customized quote based on your specific needs. Would you like to schedule a consultation?",

    // Booking and Scheduling
    booking:
      "You can book our services by contacting us directly or through our website. Would you like assistance with scheduling?",
    schedule:
      "We offer flexible scheduling to accommodate your guests' check-in and check-out times. How can we tailor our services to your schedule?",
    appointment:
      "I can help you set up an appointment to discuss your needs in detail. Would you like to schedule a meeting?",

    // Contact and Support
    contact:
      "You can reach us at info@mopmagic.ie or call us at (01) 234 5678. How can we assist you today?",
    email:
      "Feel free to email us at info@mopmagic.ie. We typically respond within 2 hours during business days.",
    phone:
      "You can call us at (01) 234 5678. Our team is available from 9 AM to 6 PM, Monday to Saturday.",
    support:
      "Our support team is here to help you with any questions or issues. How can we assist you?",

    // Operating Hours
    hours:
      "Our services are available 24/7 to meet your hosting needs. Do you have specific timing requirements?",
    time:
      "We operate around the clock to accommodate all guest schedules. Let us know your preferred times.",
    weekend:
      "Yes, we offer services on weekends and holidays to ensure continuous support for your Airbnb hosting.",

    // Special Services
    supplies:
      "We manage all restocking of essential supplies, including toiletries and kitchen basics, ensuring your guests have everything they need.",
    restock:
      "Our restocking service keeps your property fully supplied with essentials. Would you like to know what's included?",
    emergency:
      "For urgent issues, we offer 24/7 emergency support. Please call our hotline at (01) 234 5678 for immediate assistance.",

    // Location and Coverage
    location:
      "We proudly serve the entire Dublin area and surrounding suburbs. Is your property located within this region?",
    dublin:
      "Yes, Mop Magic operates throughout Dublin and nearby areas. We'd be happy to serve your property.",
    area:
      "Our services cover all of Dublin and areas within a 30km radius of the city center. Does your property fall within this area?",
  },
};

const findBestFallbackResponse = (message) => {
  const lowercaseMessage = message.toLowerCase();

  // Check for keyword matches
  for (const [keyword, response] of Object.entries(fallbackResponses.keywords)) {
    if (lowercaseMessage.includes(keyword)) {
      return response;
    }
  }

  // Return default response if no keywords match
  return fallbackResponses.default;
};

const systemContent = `You are a helpful and professional customer service assistant for Mop Magic, an Airbnb management service company based in Dublin, Ireland.

SERVICES AND PACKAGES:

1. **Starter Package** (€500/month or €6,000/year):
   - Standard Cleaning (Up to 4 turnovers per month)
   - Linen and Laundry Service
   - Restocking Essentials
   - Property Inspection

2. **Premium Package** (€850/month or €10,000/year):
   - Includes everything from the Starter Package
   - Deep Cleaning
   - Premium Restocking Essentials
   - Guest Communication Support

**Individual Services:**
- Standard Cleaning: €200/month (€2,400/year)
- Deep Cleaning: €400/month (€4,800/year)
- Linen and Laundry Service: €140/month (€1,600/year)
- Property Inspection: €70/month (€800/year)
- Restocking Essentials: €80/month (€1,000/year)
- Guest Communication Support: €110/month (€1,200/year)

**Discounts:**
- 5% off for 2 properties
- 10% off for 3 or more properties
- 25% off on annual plans

**Company Highlights:**
- Over 500 properties managed
- More than 1,000 happy guests
- 99% guest satisfaction rate
- Generated over €2 million in host revenue

**Contact Information:**
- **Phone:** (01) 234 5678
- **Email:** info@mopmagic.ie
- **Location:** Dublin, Ireland
- **Hours:** Customer service available from 9 AM to 6 PM, Monday to Saturday

**Important Guidelines:**
- Be concise yet informative.
- Provide specific pricing and service details when asked.
- Recommend packages or services based on customer needs.
- For urgent or complex inquiries, encourage customers to contact us directly.
- Maintain a friendly and professional tone.
- If unsure, politely ask for clarification or suggest contacting customer support.

If asked about terms, privacy policies, or specific company policies, direct users to the relevant section on our website at www.mopmagic.ie.`;

const checkApiKey = () => {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
  if (!apiKey) {
    throw new Error('OpenAI API key is not configured');
  }
  return apiKey;
};

const connectToOpenAI = async (messages) => {
  try {
    const apiKey = checkApiKey();
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: messages
      })
    });

    if (response.status === 429) {
      throw new Error('Rate limit reached. Please try again later.');
    }

    if (!response.ok) {
      throw new Error(`OpenAI API error: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to connect to OpenAI: ${error.message}`);
  }
};

export const getChatResponse = async (message) => {
  try {
    const messages = [
      {
        role: 'system',
        content: systemContent,
      },
      {
        role: 'user',
        content: message,
      },
    ];

    const data = await connectToOpenAI(messages);

    if (data.error) {
      console.error('OpenAI Error:', data.error);
      return findBestFallbackResponse(message);
    }

    return data.choices[0].message.content.trim();
  } catch (error) {
    console.error('Detailed Error:', error);
    return findBestFallbackResponse(message);
  }
};
