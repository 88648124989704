import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useTheme,
  useMediaQuery,
  Container,
  Badge,
  Menu,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Collapse,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import Cart from './Cart';
import logo from '../assets/logo/logo192@2x.png';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import BedIcon from '@mui/icons-material/Bed';
import SearchIcon from '@mui/icons-material/Search';
import InventoryIcon from '@mui/icons-material/Inventory';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const { state: cartState } = useCart();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  useEffect(() => {
    return () => {
      setCartOpen(false);
    };
  }, []);

  const pages = [
    { 
      title: 'Services', 
      path: '/services',
      submenu: [
        { title: 'Professional Cleaning', path: '/services/cleaning' },
        { title: 'Linen and Laundry', path: '/services/linen' },
        { title: 'Property Inspection', path: '/services/inspection' },
        { title: 'Restocking Service', path: '/services/restocking' },
        { title: 'Guest Support', path: '/services/support' },
      ]
    },
    { title: 'Pricing', path: '/pricing' },
    { title: 'About', path: '/about' },
    { title: 'Contact', path: '/contact' },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isActive = (path) => location.pathname === path;

  const handleNavigation = (path) => {
    if (path.includes('#')) {
      const element = document.getElementById(path.split('#')[1]);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate(path);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleCartOpen = () => {
    if (!cartOpen) {
      setCartOpen(true);
    }
  };

  const handleMenuItemClick = () => {
    setMobileOpen(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleServicesMouseEnter = (event) => {
    if (!isMobile) {
      setServicesAnchorEl(event.currentTarget);
    }
  };

  const handleServicesMouseLeave = () => {
    if (!isMobile) {
      setServicesAnchorEl(null);
    }
  };

  const handleServicesClick = (path) => {
    handleNavigation(path);
    setServicesAnchorEl(null);
  };

  const handleSubmenuClick = (itemTitle) => {
    setOpenSubmenu(openSubmenu === itemTitle ? '' : itemTitle);
  };

  const drawer = (
    <List sx={{ p: 2 }}>
      {pages.map((item) => (
        <React.Fragment key={item.title}>
          <ListItem
            component="div"
            onClick={() => {
              if (item.submenu) {
                handleSubmenuClick(item.title);
              } else {
                handleNavigation(item.path);
                setMobileOpen(false);
              }
            }}
            sx={{
              cursor: 'pointer',
              borderRadius: '12px',
              mb: item.submenu && openSubmenu === item.title ? 1 : 1,
              bgcolor: isActive(item.path) ? 'primary.main' : 'transparent',
              color: isActive(item.path) ? 'white' : 'text.primary',
              transition: 'all 0.3s ease',
              '&:hover': {
                bgcolor: isActive(item.path) ? 'primary.dark' : 'rgba(67, 24, 255, 0.1)',
                transform: 'translateX(5px)',
              },
            }}
          >
            <ListItemText 
              primary={item.title}
              primaryTypographyProps={{
                fontWeight: isActive(item.path) ? 600 : 500,
              }}
            />
            {item.submenu && (
              <ExpandMoreIcon 
                sx={{ 
                  transform: openSubmenu === item.title ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.3s ease',
                  color: isActive(item.path) ? 'white' : 'primary.main',
                }}
              />
            )}
          </ListItem>
          
          {item.submenu && (
            <Collapse 
              in={openSubmenu === item.title} 
              timeout={300}
              sx={{
                '& .MuiCollapse-wrapper': {
                  transition: 'all 0.3s ease',
                }
              }}
            >
              <List 
                component="div" 
                disablePadding
                sx={{
                  bgcolor: 'rgba(67, 24, 255, 0.03)',
                  borderRadius: '12px',
                  mt: 1,
                  overflow: 'hidden',
                }}
              >
                {item.submenu.map((subItem) => (
                  <ListItem
                    key={subItem.title}
                    component="div"
                    onClick={() => {
                      handleNavigation(subItem.path);
                      setMobileOpen(false);
                      setOpenSubmenu('');
                    }}
                    sx={{
                      pl: 4,
                      py: 1.5,
                      borderRadius: '0',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      bgcolor: isActive(subItem.path) ? 'primary.lighter' : 'transparent',
                      color: isActive(subItem.path) ? 'primary.main' : 'text.primary',
                      '&:hover': {
                        bgcolor: 'primary.lighter',
                        pl: 5,
                      },
                    }}
                  >
                    <Box 
                      sx={{ 
                        mr: 2,
                        color: isActive(subItem.path) ? 'primary.main' : 'text.secondary',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {item.submenu.indexOf(subItem) === 0 && <CleaningServicesIcon fontSize="small" />}
                      {item.submenu.indexOf(subItem) === 1 && <BedIcon fontSize="small" />}
                      {item.submenu.indexOf(subItem) === 2 && <SearchIcon fontSize="small" />}
                      {item.submenu.indexOf(subItem) === 3 && <InventoryIcon fontSize="small" />}
                      {item.submenu.indexOf(subItem) === 4 && <SupportAgentIcon fontSize="small" />}
                    </Box>
                    <ListItemText 
                      primary={subItem.title}
                      primaryTypographyProps={{
                        fontSize: '0.95rem',
                        fontWeight: isActive(subItem.path) ? 600 : 400,
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: scrolled ? 'rgba(255, 255, 255, 0.9)' : 'transparent',
          backdropFilter: scrolled ? 'blur(10px)' : 'none',
          boxShadow: scrolled ? '0 4px 20px -1px rgba(0,0,0,0.05)' : 'none',
          transition: 'all 0.3s ease',
        }}
      >
        <Container 
          maxWidth={false}
          sx={{ 
            maxWidth: { xs: 'calc(1200px + 48px)', lg: 'calc(1200px + 96px)' }
          }}
        >
          <Toolbar
            sx={{
              py: 1,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <RouterLink 
              to="/" 
              onClick={handleLogoClick}
              style={{ 
                textDecoration: 'none', 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 1, md: 1.5 }
              }}
            >
              <Box
                component="img"
                src={logo}
                alt="Mop Magic"
                sx={{
                  height: { xs: '50px', md: '90px' },
                  width: 'auto',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 800,
                  display: 'block',
                  background: 'linear-gradient(135deg, #4318FF 0%, #39B8FF 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  fontSize: { xs: '1.2rem', md: '2rem', lg: '2.2rem' },
                }}
              >
                Mop Magic
              </Typography>
            </RouterLink>

            {isMobile ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button
                  onClick={handleCartOpen}
                  startIcon={
                    <Badge badgeContent={cartState.items.length} color="primary">
                      <ShoppingCartIcon />
                    </Badge>
                  }
                  variant="outlined"
                  size="small"
                >
                  Cart
                </Button>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    color: 'text.primary',
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {pages.map((item) => (
                  <React.Fragment key={item.title}>
                    <Button
                      onMouseEnter={item.submenu ? handleServicesMouseEnter : undefined}
                      onClick={() => item.submenu ? null : handleNavigation(item.path)}
                      className="no-elevation no-hover"
                      sx={{
                        mx: 1,
                        px: 2,
                        py: 1,
                        borderRadius: '12px',
                        color: isActive(item.path) ? 'primary.main' : 'text.primary',
                        bgcolor: isActive(item.path) ? 'primary.lighter' : 'transparent',
                        fontWeight: isActive(item.path) ? 600 : 500,
                        fontSize: '1.1rem',
                        '&:hover': {
                          bgcolor: isActive(item.path) ? 'primary.lighter' : 'rgba(67, 24, 255, 0.1)',
                          transform: 'none',
                        },
                      }}
                    >
                      {item.title}
                      {item.submenu && <ExpandMoreIcon sx={{ ml: 0.5 }} />}
                    </Button>
                    {item.submenu && (
                      <Popper
                        open={Boolean(servicesAnchorEl)}
                        anchorEl={servicesAnchorEl}
                        placement="bottom-start"
                        transition
                        onMouseLeave={handleServicesMouseLeave}
                        sx={{ zIndex: 1300 }}
                      >
                        {({ TransitionProps }) => (
                          <Grow {...TransitionProps}>
                            <Paper
                              elevation={0}
                              sx={{
                                mt: 1,
                                borderRadius: '16px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                              }}
                            >
                              <ClickAwayListener onClickAway={() => setServicesAnchorEl(null)}>
                                <Box>
                                  {item.submenu.map((subItem) => (
                                    <MenuItem
                                      key={subItem.title}
                                      onClick={() => handleServicesClick(subItem.path)}
                                      sx={{
                                        py: 1.5,
                                        px: 3,
                                        minWidth: 250,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        '&:hover': {
                                          bgcolor: 'primary.lighter',
                                        },
                                      }}
                                    >
                                      <Box 
                                        sx={{ 
                                          color: isActive(subItem.path) ? 'primary.main' : 'text.secondary',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {item.submenu.indexOf(subItem) === 0 && <CleaningServicesIcon fontSize="small" />}
                                        {item.submenu.indexOf(subItem) === 1 && <BedIcon fontSize="small" />}
                                        {item.submenu.indexOf(subItem) === 2 && <SearchIcon fontSize="small" />}
                                        {item.submenu.indexOf(subItem) === 3 && <InventoryIcon fontSize="small" />}
                                        {item.submenu.indexOf(subItem) === 4 && <SupportAgentIcon fontSize="small" />}
                                      </Box>
                                      {subItem.title}
                                    </MenuItem>
                                  ))}
                                </Box>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    )}
                  </React.Fragment>
                ))}
                <Button
                  onClick={handleCartOpen}
                  className="no-elevation no-hover"
                  startIcon={
                    <Badge badgeContent={cartState.items.length} color="primary">
                      <ShoppingCartIcon />
                    </Badge>
                  }
                  variant="outlined"
                  sx={{ 
                    ml: 2,
                    fontSize: '1.1rem',
                    '&:hover': {
                      transform: 'none',
                    },
                  }}
                >
                  Cart
                </Button>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: 280,
            borderRadius: '16px 0 0 16px',
            mt: 8,
          },
        }}
      >
        {drawer}
      </Drawer>

      {cartOpen && <Cart open={cartOpen} onClose={() => setCartOpen(false)} />}
      
      <Toolbar /> {/* Spacer for fixed AppBar */}
    </>
  );
};

export default Header; 