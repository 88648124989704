import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import Layout from './components/Layout';
import { CartProvider } from './context/CartContext';
import CookiesBanner from './components/CookiesBanner';
import { ChatProvider } from './context/ChatContext';
import LoadingSkeleton from './components/LoadingSkeleton';
import { ResizeObserverProvider } from './providers/ResizeObserverProvider';

// Lazy load the pages
const Home = React.lazy(() => import('./pages/Home'));
const Pricing = React.lazy(() => import('./pages/Pricing'));
const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Careers = React.lazy(() => import('./pages/Careers'));
const Terms = React.lazy(() => import('./pages/legal/Terms'));
const Privacy = React.lazy(() => import('./pages/legal/Privacy'));
const Cookies = React.lazy(() => import('./pages/legal/Cookies'));
const Services = React.lazy(() => import('./pages/Services'));
const ServiceCleaning = React.lazy(() => import('./pages/services/Cleaning'));
const ServiceLinen = React.lazy(() => import('./pages/services/Linen'));
const ServiceInspection = React.lazy(() => import('./pages/services/Inspection'));
const ServiceRestocking = React.lazy(() => import('./pages/services/Restocking'));
const ServiceSupport = React.lazy(() => import('./pages/services/Support'));

function App() {
  return (
    <ResizeObserverProvider>
      <ThemeProvider theme={theme}>
        <CartProvider>
          <ChatProvider>
            <Layout>
              <Suspense fallback={<LoadingSkeleton />}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/careers" element={<Careers />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/cookies" element={<Cookies />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/services/cleaning" element={<ServiceCleaning />} />
                  <Route path="/services/linen" element={<ServiceLinen />} />
                  <Route path="/services/inspection" element={<ServiceInspection />} />
                  <Route path="/services/restocking" element={<ServiceRestocking />} />
                  <Route path="/services/support" element={<ServiceSupport />} />
                </Routes>
              </Suspense>
              <CookiesBanner />
            </Layout>
          </ChatProvider>
        </CartProvider>
      </ThemeProvider>
    </ResizeObserverProvider>
  );
}

export default App;
