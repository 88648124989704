import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Link, 
  Stack,
  Slide 
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CookiesBanner = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setOpen(false);
  };

  if (!open) return null;

  return (
    <Slide direction="up" in={open}>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          p: 2,
          zIndex: 1300,
          boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={{ maxWidth: 'lg', mx: 'auto' }}>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2} 
            alignItems={{ xs: 'stretch', sm: 'center' }}
            justifyContent="space-between"
          >
            <Typography variant="body2" sx={{ maxWidth: 800 }}>
              We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies. Learn more in our{' '}
              <Link component={RouterLink} to="/cookies">Cookie Policy</Link>.
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button 
                variant="contained" 
                onClick={handleAccept}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Accept All
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Slide>
  );
};

export default CookiesBanner; 