import React from 'react';
import { Box, Container, Grid, Skeleton } from '@mui/material';

const LoadingSkeleton = () => {
  return (
    <Box sx={{ py: { xs: 20, md: 28 } }}>
      <Container maxWidth="lg">
        {/* Hero Section Skeleton */}
        <Grid container spacing={8} alignItems="center">
          {/* Left Content */}
          <Grid item xs={12} md={6}>
            <Skeleton 
              variant="text" 
              sx={{ 
                fontSize: { xs: '2.5rem', md: '3.5rem' },
                mb: 3,
                width: '90%',
                background: 'linear-gradient(135deg, rgba(67, 24, 255, 0.1) 0%, rgba(57, 184, 255, 0.1) 100%)',
              }} 
            />
            <Skeleton 
              variant="text" 
              sx={{ 
                fontSize: '1.25rem',
                mb: 2,
                width: '80%' 
              }} 
            />
            <Skeleton 
              variant="text" 
              sx={{ 
                fontSize: '1.25rem',
                mb: 4,
                width: '70%' 
              }} 
            />
            <Skeleton 
              variant="rectangular" 
              sx={{ 
                width: 200,
                height: 50,
                borderRadius: '12px',
                background: 'linear-gradient(135deg, rgba(67, 24, 255, 0.1) 0%, rgba(57, 184, 255, 0.1) 100%)',
              }} 
            />
          </Grid>

          {/* Right Image */}
          <Grid item xs={12} md={6}>
            <Skeleton 
              variant="rectangular" 
              sx={{ 
                width: '100%',
                height: { xs: 300, md: 400 },
                borderRadius: 4,
                transform: 'none',
                background: 'linear-gradient(135deg, rgba(67, 24, 255, 0.1) 0%, rgba(57, 184, 255, 0.1) 100%)',
              }} 
            />
          </Grid>
        </Grid>

        {/* Features Section Skeleton */}
        <Box sx={{ mt: { xs: 8, md: 12 } }}>
          <Grid container spacing={4}>
            {[1, 2, 3].map((item) => (
              <Grid item xs={12} md={4} key={item}>
                <Skeleton 
                  variant="rectangular" 
                  sx={{ 
                    width: '100%',
                    height: 200,
                    borderRadius: 4,
                    mb: 2,
                    background: 'linear-gradient(135deg, rgba(67, 24, 255, 0.05) 0%, rgba(57, 184, 255, 0.05) 100%)',
                  }} 
                />
                <Skeleton 
                  variant="text" 
                  sx={{ 
                    fontSize: '1.5rem',
                    width: '60%',
                    mb: 1 
                  }} 
                />
                <Skeleton 
                  variant="text" 
                  sx={{ 
                    fontSize: '1rem',
                    width: '80%' 
                  }} 
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* CTA Section Skeleton */}
        <Box 
          sx={{ 
            mt: { xs: 8, md: 12 },
            p: 4,
            borderRadius: '24px',
            background: 'linear-gradient(135deg, rgba(67, 24, 255, 0.1) 0%, rgba(57, 184, 255, 0.1) 100%)',
          }}
        >
          <Skeleton 
            variant="text" 
            sx={{ 
              fontSize: '2rem',
              width: '60%',
              mx: 'auto',
              mb: 2 
            }} 
          />
          <Skeleton 
            variant="text" 
            sx={{ 
              fontSize: '1.25rem',
              width: '80%',
              mx: 'auto',
              mb: 3 
            }} 
          />
          <Skeleton 
            variant="rectangular" 
            sx={{ 
              width: 200,
              height: 50,
              borderRadius: '12px',
              mx: 'auto',
              background: 'white',
              opacity: 0.2
            }} 
          />
        </Box>
      </Container>
    </Box>
  );
};

export default LoadingSkeleton; 